<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenConfig.machine_name') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="machine_name"
            >
              <template v-slot:label>
                {{ $t('teaGardenConfig.machine_name') }}
              </template>
              <b-form-input
                  id="machine_name"
                  type="text"
                  v-model="search.machine_name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title"> {{ $t('teaGardenConfig.machine_name') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <base-table v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :columns="columns" :componentId="3" :search="search" :searchData="searchData" :paginations="pagination" :actions="actions" :imageUrl ="teaGardenServiceBaseUrl" />
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { machineNameList, machineNameToggle } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      search: {
        machine_name: '',
        limit: 20
      },
      typeList: [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Tea Factory' : 'চা কারখানা', text_en: 'Tea Factory', text_bn: 'চা কারখানা' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Agriculture' : 'কৃষি', text_en: 'Agriculture', text_bn: 'কৃষি' }
      ],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
        { labels: 'globalTrans.type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: 'auto' } },
        { labels: 'teaGardenConfig.machine_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 4, thStyle: { width: '12%' } }
      ],
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const locale = this.$i18n.locale
      const keys = [
        { key: 'serial' },
        { key: `type_name${locale === 'bn' ? '_bn' : ''}` },
        { key: `machine_name_${locale === 'bn' ? 'bn' : 'en'}` },
        { key: 'status' },
        { key: 'action' }
      ]
      return labelData.map((item, index) => ({
        ...item,
        label: this.$t(item.labels, locale),
        ...keys[index]
      }))
    },
    formTitle () {
      const entryOrModify = this.editItemId === 0 ? this.$t('globalTrans.entry') : this.$t('globalTrans.modify')
      return this.$t('teaGardenConfig.machine_name') + ' ' + entryOrModify
    },
    factoryTypeList () {
      return this.$store.state.TeaGardenService.commonObj.masterFactoryTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async editMethodInParent (item, type) {
      type === 1 ? this.edit(item) : this.changeStatus(3, teaGardenServiceBaseUrl, machineNameToggle, item)
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      try {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const params = { ...this.search, page: this.pagination.currentPage, per_page: this.pagination.perPage }
        const result = await RestApi.getData(teaGardenServiceBaseUrl, machineNameList, params)

        if (result.success) {
          const listData = this.transformData(result.data.data)
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
      } catch (error) {
        this.$store.dispatch('setList', [])
        this.paginationData([])
        window.vm.$toast.error({
          title: 'Error',
          message: error
        })
      } finally {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    transformData (data) {
      return data.map((item, index) => ({
        ...item,
        serial: index,
        ...this.getTypeData(item.type)
      }))
    },
    getTypeData (type) {
      const typeObj = this.typeList.find((t) => t.value === parseInt(type)) || {}
      return {
        type_name: typeObj.text_en || '',
        type_name_bn: typeObj.text_bn || ''
      }
    }
  }
}
</script>
